:root {
  --cal-width: 50px;
  --cal-height: 10px; }

html, body, #root, .App {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0; }

.rel {
  position: relative; }

.abs {
  position: absolute; }
  .abs.center-x {
    left: 50%;
    transform: translateX(-50%); }
  .abs.bot15 {
    bottom: 15px; }
  .abs.top15 {
    top: 15px; }

.container-full {
  min-height: 100%; }

.flex {
  display: flex; }
  .flex.col {
    flex-direction: column; }
  .flex.col-r {
    flex-direction: column-reverse; }
  .flex.row {
    flex-direction: row; }
  .flex.row-r {
    flex-direction: row-reverse; }
  .flex.center {
    justify-content: center;
    align-items: center; }
  .flex.sb {
    justify-content: space-between;
    align-items: center; }
  .flex.left {
    justify-content: flex-start;
    align-items: flex-start; }
  .flex.right {
    justify-content: flex-end;
    align-items: flex-end; }
  .flex.start {
    justify-content: flex-start;
    align-items: center; }
  .flex.wrap {
    flex-wrap: wrap; }

.w100 {
  width: 100%; }

.w90 {
  width: 90%; }

.w60 {
  width: 60%; }

.h30px {
  height: 30px; }

.pd0 {
  padding: 0; }

.pd5 {
  padding: 5px; }

.pd10 {
  padding: 10px; }

.pd15 {
  padding: 15px; }

.pd20 {
  padding: 20px; }

.pdt60 {
  padding-top: 60px; }

.pdt80 {
  padding-top: 80px; }

.pdt120 {
  padding-top: 120px; }

.pdcontainer20 {
  padding: 0 20% 0 20%; }

.pdcontainer5 {
  padding: 0 5% 0 5%; }

.mg0 {
  margin: 0; }

.mg5 {
  margin: 5px; }

.mg10 {
  margin: 10px; }

.mg15 {
  margin: 15px; }

.mg20 {
  margin: 20px; }

.mgt60 {
  margin-top: 60px; }

.form.size300 {
  width: 300px;
  min-height: 200px; }

.form.classic {
  padding: 20px;
  border-top: 3px solid #7DC0F4; }
  .form.classic label, .form.classic input, .form.classic svg {
    height: 30px; }
  .form.classic input[type="email"], .form.classic input[type="password"], .form.classic input[type="text"] {
    width: 80%; }

.btn {
  border-radius: 0;
  width: 100px;
  height: 30px;
  transition: all ease-in-out .5s; }
  .btn.blue1 {
    border: 1px solid #3498db;
    background-color: #3498db;
    color: #fff; }
  .btn.light1 {
    border: 1px solid #3A7EC5;
    background-color: #3A7EC5;
    color: #fff; }
    .btn.light1:hover {
      background-color: #fff;
      color: #3A7EC5; }
  .btn:hover {
    cursor: pointer; }

.btn2 {
  border-radius: 0;
  width: 30px;
  height: 30px;
  margin: 0 20px 0 20px; }
  .btn2.blue1 {
    border: 1px solid #3498db;
    background-color: #3498db;
    color: #fff; }
  .btn2.bg1 {
    border: 1px solid #2c3e50;
    background-color: #2c3e50;
    color: #fff; }
  .btn2.bg3L {
    border: 1px solid #FFF;
    background-color: #FFF;
    color: #3E86D1; }
  .btn2.light1 {
    border: 1px solid #3A7EC5;
    background-color: #3A7EC5;
    color: #fff; }
  .btn2:hover {
    cursor: pointer; }

.clickable:hover {
  cursor: pointer; }

.h2, .h2-sm, .h2-md {
  color: #fff; }

.h2-sm {
  font-size: 20px; }

.h3, .h3-sm, .h3-md {
  color: #fff; }

.h3-sm {
  font-size: 16px; }

.h3, .h3-md {
  font-size: 18px; }

.h4, .h4-sm, .h4-md {
  color: #fff; }

.h4-sm {
  font-size: 12px; }

.h4, .h4-md {
  font-size: 14px; }

.text-center {
  text-align: center; }

.bg1 {
  background-color: #2c3e50; }

.bg2 {
  background-color: #34495e; }

.bg3 {
  background-color: #95a5a6; }

.bg1L {
  background-color: #4898ED; }

.bg2L {
  background-color: #7DC0F4; }

.bg3L {
  background-color: #FFF; }

.text1 {
  color: #fff; }

.text2 {
  color: #ccc; }

.text3 {
  color: #999; }

.text1L {
  color: #4898ED; }

.text2L {
  color: #3E86D1; }

.text3L {
  color: #fff; }

.green1 {
  color: #2ecc71; }

.blue1 {
  color: #3498db; }

.blue2 {
  color: #3A7EC5; }

.orange1 {
  color: #e67e22; }

.red1 {
  color: #e74c3c; }

.yellow1 {
  color: #f1c40f; }
