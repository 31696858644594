.calendrier-small {
  width: 336px;
  background-color: #fff;
  padding: 10px; }
  .calendrier-small .row {
    height: 32px; }
    .calendrier-small .row .header {
      font-size: 12px;
      margin: 10px 0 5px 0;
      width: 48px;
      height: 32px;
      text-align: center;
      color: #34495e; }
    .calendrier-small .row select {
      border: 1px solid silver;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-right: 25px;
      background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
      background-repeat: no-repeat;
      background-position: 95% 42%;
      width: 120px;
      padding-left: 10px; }
      .calendrier-small .row select:hover {
        cursor: pointer; }
  .calendrier-small .calendrier-row {
    width: 336px; }
    .calendrier-small .calendrier-row .calendrier-case {
      width: 48px;
      height: 32px; }
      .calendrier-small .calendrier-row .calendrier-case .day-btn {
        background-color: transparent;
        border: 0px solid transparent;
        width: 48px;
        height: 32px;
        padding: 0;
        margin: 0; }
        .calendrier-small .calendrier-row .calendrier-case .day-btn.today {
          color: #3498db;
          text-decoration: underline; }
      .calendrier-small .calendrier-row .calendrier-case.onlySel {
        border-radius: 15px; }
      .calendrier-small .calendrier-row .calendrier-case.startSel {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px; }
      .calendrier-small .calendrier-row .calendrier-case.endSel {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px; }
      .calendrier-small .calendrier-row .calendrier-case.startSel, .calendrier-small .calendrier-row .calendrier-case.endSel, .calendrier-small .calendrier-row .calendrier-case.sel, .calendrier-small .calendrier-row .calendrier-case.onlySel {
        background-color: #3498db; }
        .calendrier-small .calendrier-row .calendrier-case.startSel .day-btn.today, .calendrier-small .calendrier-row .calendrier-case.endSel .day-btn.today, .calendrier-small .calendrier-row .calendrier-case.sel .day-btn.today, .calendrier-small .calendrier-row .calendrier-case.onlySel .day-btn.today {
          color: #fff;
          text-decoration: underline; }
      .calendrier-small .calendrier-row .calendrier-case.not-month .day-btn {
        color: #95a5a6; }
      .calendrier-small .calendrier-row .calendrier-case p {
        width: 90px;
        height: 20px;
        margin: 0 !important;
        padding: 5px;
        font-weight: bold;
        background-color: #2c3e50; }
        .calendrier-small .calendrier-row .calendrier-case p.today {
          color: #2c3e50;
          background-color: #3498db; }
      .calendrier-small .calendrier-row .calendrier-case div {
        border-top: 1px solid #999;
        width: 100px;
        height: 69px;
        color: #3498db; }
        .calendrier-small .calendrier-row .calendrier-case div.booked {
          background-color: #bdc3c7;
          border-top: none;
          border-bottom: 2px solid #bdc3c7; }
          .calendrier-small .calendrier-row .calendrier-case div.booked:hover {
            cursor: pointer; }
          .calendrier-small .calendrier-row .calendrier-case div.booked.no-h:hover {
            cursor: default; }
        .calendrier-small .calendrier-row .calendrier-case div.free {
          background-color: #2ecc71;
          border-top: none;
          border-bottom: 2px solid #2ecc71; }

.calendar-infos .calendar-taches-title,
.calendar-infos-sm .calendar-taches-title,
.calendar-infos-md .calendar-taches-title {
  color: #3E86D1; }

.calendar-infos .calendrier-taches,
.calendar-infos-sm .calendrier-taches,
.calendar-infos-md .calendrier-taches {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%; }
  .calendar-infos .calendrier-taches .tache-container,
  .calendar-infos-sm .calendrier-taches .tache-container,
  .calendar-infos-md .calendrier-taches .tache-container {
    width: 100%; }
    .calendar-infos .calendrier-taches .tache-container h2,
    .calendar-infos-sm .calendrier-taches .tache-container h2,
    .calendar-infos-md .calendrier-taches .tache-container h2 {
      color: #fff;
      width: 100%;
      text-align: center; }
      .calendar-infos .calendrier-taches .tache-container h2:hover,
      .calendar-infos-sm .calendrier-taches .tache-container h2:hover,
      .calendar-infos-md .calendrier-taches .tache-container h2:hover {
        cursor: help; }
    .calendar-infos .calendrier-taches .tache-container .sous-tache,
    .calendar-infos-sm .calendrier-taches .tache-container .sous-tache,
    .calendar-infos-md .calendrier-taches .tache-container .sous-tache {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .calendar-infos .calendrier-taches .tache-container .sous-tache:hover,
      .calendar-infos-sm .calendrier-taches .tache-container .sous-tache:hover,
      .calendar-infos-md .calendrier-taches .tache-container .sous-tache:hover {
        cursor: help; }
      .calendar-infos .calendrier-taches .tache-container .sous-tache h3,
      .calendar-infos-sm .calendrier-taches .tache-container .sous-tache h3,
      .calendar-infos-md .calendrier-taches .tache-container .sous-tache h3 {
        color: #333;
        width: 100%;
        text-align: left; }
      .calendar-infos .calendrier-taches .tache-container .sous-tache .sous-tache-etat,
      .calendar-infos-sm .calendrier-taches .tache-container .sous-tache .sous-tache-etat,
      .calendar-infos-md .calendrier-taches .tache-container .sous-tache .sous-tache-etat {
        width: 20px;
        height: 20px; }

.calendar-infos {
  width: 400px;
  padding: 0 0 0 5%; }

.calendar-infos-sm {
  width: 90%;
  padding: 0 5% 0 5%; }

.calendar-infos-md {
  width: 400px;
  padding: 0 0 0 5%; }

.calendrier {
  flex-grow: 1;
  padding: 0 5% 0 5%; }
  .calendrier h2 {
    color: #4898ED; }
  .calendrier .row .header {
    margin: 5px 0;
    width: var(--cal-width);
    border: 2px transparent black;
    height: 20px;
    text-align: center;
    color: #3E86D1; }
  .calendrier .calendrier-row .calendrier-case {
    position: relative;
    height: 100px;
    border-left: 1px solid #999;
    border-top: 1px solid #999;
    color: #3498db;
    flex-grow: 1;
    background-color: #F5F8FA; }
    .calendrier .calendrier-row .calendrier-case.small {
      height: 60px; }
    .calendrier .calendrier-row .calendrier-case .tache-row {
      max-width: var(--cal-width); }
    .calendrier .calendrier-row .calendrier-case.not-month p {
      opacity: 0.2; }
    .calendrier .calendrier-row .calendrier-case.not-month div {
      opacity: 0.2; }
    .calendrier .calendrier-row .calendrier-case p {
      width: 15px;
      height: 15px;
      margin: 0 !important;
      padding: 5px;
      font-weight: bold;
      background-color: #F5F8FA;
      color: #333;
      font-size: 12px;
      text-align: center; }
      .calendrier .calendrier-row .calendrier-case p.today {
        color: #fff;
        background-color: #4898ED;
        font-size: 13px; }
    .calendrier .calendrier-row .calendrier-case div {
      position: absolute;
      width: 0px;
      left: 0;
      height: var(--cal-height); }
    .calendrier .calendrier-row .calendrier-case:last-child {
      border-right: 1px solid #999; }
  .calendrier .calendrier-row:last-child .calendrier-case {
    border-bottom: 1px solid #ccc; }

@keyframes heightTo20 {
  from {
    width: 0px; }
  to {
    width: var(--cal-width); } }
