.annonceur-container, .annonceur-container-sm, .annonceur-container-md {
  height: 380px;
  border: 2px solid #7DC0F4;
  background-color: #F5F8FA;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 20px 0;
  color: #4898ED; }
  .annonceur-container .scroll, .annonceur-container-sm .scroll, .annonceur-container-md .scroll {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto; }
    .annonceur-container .scroll .annonce, .annonceur-container-sm .scroll .annonce, .annonceur-container-md .scroll .annonce {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 10px; }
      .annonceur-container .scroll .annonce .datetime, .annonceur-container .scroll .annonce .datetime-sm, .annonceur-container .scroll .annonce .datetime-md, .annonceur-container-sm .scroll .annonce .datetime, .annonceur-container-sm .scroll .annonce .datetime-sm, .annonceur-container-sm .scroll .annonce .datetime-md, .annonceur-container-md .scroll .annonce .datetime, .annonceur-container-md .scroll .annonce .datetime-sm, .annonceur-container-md .scroll .annonce .datetime-md {
        display: block;
        width: 200px;
        padding-left: 20px; }
      .annonceur-container .scroll .annonce .datetime-sm, .annonceur-container-sm .scroll .annonce .datetime-sm, .annonceur-container-md .scroll .annonce .datetime-sm {
        width: 120px; }
      .annonceur-container .scroll .annonce .desc, .annonceur-container .scroll .annonce .desc-sm, .annonceur-container .scroll .annonce .desc-md, .annonceur-container-sm .scroll .annonce .desc, .annonceur-container-sm .scroll .annonce .desc-sm, .annonceur-container-sm .scroll .annonce .desc-md, .annonceur-container-md .scroll .annonce .desc, .annonceur-container-md .scroll .annonce .desc-sm, .annonceur-container-md .scroll .annonce .desc-md {
        flex-grow: 1; }
      .annonceur-container .scroll .annonce .desc-sm, .annonceur-container-sm .scroll .annonce .desc-sm, .annonceur-container-md .scroll .annonce .desc-sm {
        padding: 0 5%;
        width: 90%; }
      .annonceur-container .scroll .annonce .datetime.pair, .annonceur-container .scroll .annonce .datetime-md.pair, .annonceur-container .scroll .annonce .datetime-sm.pair, .annonceur-container .scroll .annonce .desc.pair, .annonceur-container .scroll .annonce .desc-sm.pair, .annonceur-container .scroll .annonce .desc-md.pair, .annonceur-container-sm .scroll .annonce .datetime.pair, .annonceur-container-sm .scroll .annonce .datetime-md.pair, .annonceur-container-sm .scroll .annonce .datetime-sm.pair, .annonceur-container-sm .scroll .annonce .desc.pair, .annonceur-container-sm .scroll .annonce .desc-sm.pair, .annonceur-container-sm .scroll .annonce .desc-md.pair, .annonceur-container-md .scroll .annonce .datetime.pair, .annonceur-container-md .scroll .annonce .datetime-md.pair, .annonceur-container-md .scroll .annonce .datetime-sm.pair, .annonceur-container-md .scroll .annonce .desc.pair, .annonceur-container-md .scroll .annonce .desc-sm.pair, .annonceur-container-md .scroll .annonce .desc-md.pair {
        color: #000; }
      .annonceur-container .scroll .annonce .datetime.impair, .annonceur-container .scroll .annonce .datetime-md.impair, .annonceur-container .scroll .annonce .datetime-sm.impair, .annonceur-container .scroll .annonce .desc.impair, .annonceur-container .scroll .annonce .desc-sm.impair, .annonceur-container .scroll .annonce .desc-md.impair, .annonceur-container-sm .scroll .annonce .datetime.impair, .annonceur-container-sm .scroll .annonce .datetime-md.impair, .annonceur-container-sm .scroll .annonce .datetime-sm.impair, .annonceur-container-sm .scroll .annonce .desc.impair, .annonceur-container-sm .scroll .annonce .desc-sm.impair, .annonceur-container-sm .scroll .annonce .desc-md.impair, .annonceur-container-md .scroll .annonce .datetime.impair, .annonceur-container-md .scroll .annonce .datetime-md.impair, .annonceur-container-md .scroll .annonce .datetime-sm.impair, .annonceur-container-md .scroll .annonce .desc.impair, .annonceur-container-md .scroll .annonce .desc-sm.impair, .annonceur-container-md .scroll .annonce .desc-md.impair {
        color: #666; }

.annonceur-container {
  width: 932px; }

.annonceur-container-md {
  width: 80%; }

.annonceur-container-sm {
  width: 90%; }
