.container-compteur {
  width: 280px;
  height: 280px;
  border: 2px solid #7DC0F4;
  margin: 20px;
  background-color: #F5F8FA;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #4898ED; }
  .container-compteur .container-compteur-inner {
    width: 200px;
    height: 200px; }
    .container-compteur .container-compteur-inner .compteur {
      margin: 20px; }
      .container-compteur .container-compteur-inner .compteur .progressbar-text {
        text-align: center;
        color: #333 !important; }
