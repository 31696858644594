nav {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #4898ED;
  height: 60px;
  z-index: 1000; }
  nav .projets {
    position: absolute;
    top: 15px;
    left: 5%; }
    nav .projets h3 {
      padding-top: 3px;
      height: 30px;
      margin: 0 20px 0 0;
      color: #fff; }
    nav .projets select, nav .projets svg {
      height: 30px;
      font-size: 16px;
      border: 2px solid silver;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-right: 25px;
      background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
      background-repeat: no-repeat;
      background-position: 95% 42%;
      padding-left: 10px;
      background-color: #fff;
      flex-grow: 1; }
      nav .projets select:hover, nav .projets svg:hover {
        cursor: pointer; }
    nav .projets svg {
      width: 30px;
      border: none;
      color: #3498db;
      background-color: #4898ED; }
  nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 58px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: #fff; }
    nav a.small {
      width: 50px;
      height: 30px; }
    nav a.active {
      background-color: white;
      color: #4898ED; }
  nav button {
    position: absolute;
    top: 3px;
    right: 5%;
    height: 54px;
    width: 50px;
    background-color: #4898ED;
    color: white;
    border: 0; }
    nav button:hover {
      cursor: pointer; }
